import React, { useCallback, useEffect, useMemo, useState } from "react";
import InlineSVG from "react-inlinesvg/esm";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import checkmark from "../../assets/images/checkmark.svg";
import COUNTRIES, {
  getCountryByCode,
  isStateMandatory,
} from "../../helpers/countries";
import { getCountryStates } from "../../helpers/states";
import { rem, toREM, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { KlaviyoService } from "../../services/klaviyo";
import { FormstackService } from "../../services/formstack";
import Button, { ButtonType } from "../shared/Button";
import Checkbox from "../shared/Checkbox/Checkbox";
import Input from "../shared/Input/Input";
import TextArea from "../shared/TextArea/TextArea";
import Select from "./Select";
import ReCAPTCHA from "react-google-recaptcha";
import { useMessages } from "../../hooks/useMessages";
import { pushGtmDataLayer } from "../../helpers/analytics";

export type FormPayload =
  | {
      country?: string;
      firstName?: string;
      lastName?: string;
      businessName?: string;
      city?: string;
      state?: string;
      email?: string;
      phone?: string;
      products?: string;
      distributors?: string;
      agreement: boolean;
    }
  | Record<string, any>;

export type StartSellingFormLabels = {
  title?: string;
  description: string;
  display?: string;
  successMessage: string;
  klaviyoListId: string;
  first_step: {
    tab_form_title: string;
    country_placeholder: string;
    first_name_placeholder: string;
    last_name_placeholder: string;
    business_name_placeholder: string;
    city_placeholder: string;
    state_province_placeholder: string;
    button_link: string;
    button_label: string;
  };
  second_step: {
    tab_form_title: string;
    email_placeholder: string;
    phone_placeholder: string;
    which_product_placeholder: string;
    which_distributors_placeholder: string;
    disclaimer: string;
    left_button_label: string;
    left_button_link: string;
    right_button_label: string;
    right_button_link: string;
  };
};

export type FormProps = StartSellingFormLabels;

const FORM_INITIAL_STATE: FormPayload = {
  state: "",
  city: "",
  country: "",
  businessName: "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  distributors: "",
  products: "",
  agreement: true,
};

export default function Form({
  title,
  description,
  successMessage,
  klaviyoListId,
  first_step,
  second_step,
}: FormProps): React.ReactElement {
  const recaptchaRef = React.useRef();
  const [step, setStep] = useState(1);
  const [form, setForm] = useState<FormPayload>(FORM_INITIAL_STATE);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>();

  const states = useMemo(() => getCountryStates(form.country), [form.country]);

  const messages = useMessages();

  const isFirstStepValid = useCallback(() => {
    return (
      form?.country &&
      form.firstName &&
      form?.lastName &&
      form?.businessName &&
      form?.city &&
      (isStateMandatory(form?.country) ? form?.state : true)
    );
  }, [form]);

  const isSecondStepValid = useCallback(() => {
    return (
      form?.email &&
      isEmail(form.email) &&
      form?.phone &&
      isMobilePhone(form.phone) &&
      form?.distributors &&
      form?.products
    );
  }, [form]);

  const handleNext = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setErrorMessages({
        firstName: !form.firstName ? messages.fieldRequired : "",
        lastName: !form.lastName ? messages.fieldRequired : "",
        businessName: !form.businessName ? messages.fieldRequired : "",
        country: !form.country ? messages.fieldRequired : "",
        city: !form.city ? messages.fieldRequired : "",
        state:
          isStateMandatory(form?.country) && !form.state
            ? messages.fieldRequired
            : "",
      });
      if (!isFirstStepValid()) {
        return;
      }
      setStep(2);

      pushGtmDataLayer({
        event: "start_selling_start",
      });
    },
    [isFirstStepValid]
  );

  const back = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setStep(1);
  }, []);

  const handleSubmit = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      setErrorMessages({
        email: !form.email
          ? messages.fieldRequired
          : !isEmail(form?.email)
          ? messages.validEmail
          : "",
        phone: !form.phone
          ? messages.fieldRequired
          : !isMobilePhone(form?.phone)
          ? messages.validPhoneNumber
          : "",
        distributors: !form.distributors ? messages.fieldRequired : "",
        products: !form.products ? messages.fieldRequired : "",
      });

      if (!isFirstStepValid()) {
        return;
      }
      if (!isSecondStepValid()) {
        return;
      }
      try {
        await recaptchaRef.current?.executeAsync();
        await KlaviyoService.contactSalesFoodService(klaviyoListId, {
          city: form?.city,
          country: {
            code: form?.country,
            name: getCountryByCode(form?.country)?.Name,
          },
          state: form?.state,
          firstName: form?.firstName,
          phoneNumber: form?.phone,
          products: form?.products,
          distributors: form?.distributors,
          email: form?.email,
          businessName: form?.businessName,
          lastName: form?.lastName,
          agreement: form?.agreement,
        });
        await FormstackService.contactSales({
          city: form?.city,
          country: {
            code: form?.country,
            name: getCountryByCode(form?.country)?.Name,
          },
          state: form?.state,
          firstName: form?.firstName,
          phoneNumber: form?.phone,
          products: form?.products,
          distributors: form?.distributors,
          email: form?.email,
          businessName: form?.businessName,
          lastName: form?.lastName,
          agreement: form?.agreement,
        });
        const tId = setTimeout(() => {
          setSuccess(false);
          setForm(FORM_INITIAL_STATE);
          setStep(1);
          clearTimeout(tId);
        }, 10000);
        setSuccess(true);

        pushGtmDataLayer({
          event: "start_selling_finish",
        });
      } catch (error) {
        console.log(error);
      }
    },
    [form, isFirstStepValid, isSecondStepValid]
  );

  const handleChange = useCallback((event: React.FormEvent) => {
    const { name, value, checked, type } = event.target as HTMLInputElement;
    setForm((p) => ({
      ...p,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  const renderContent = () => {
    if (success) {
      return <SuccessMessage>{successMessage}</SuccessMessage>;
    }
    if (step === 1) {
      return (
        <>
          <Fields>
            <Select
              aria-label={first_step?.country_placeholder}
              placeHolder={first_step?.country_placeholder}
              name="country"
              value={form.country}
              onChange={handleChange}
              options={COUNTRIES}
              labelField="Name"
              valueField="Code"
              error={errorMessages?.country}
            />
            <Input
              label={first_step?.first_name_placeholder}
              name="firstName"
              value={form.firstName}
              onChange={handleChange}
              error={errorMessages?.firstName}
            />
            <Input
              label={first_step?.last_name_placeholder}
              name="lastName"
              onChange={handleChange}
              value={form.lastName}
              error={errorMessages?.lastName}
            />
            <Input
              label={first_step?.business_name_placeholder}
              name="businessName"
              onChange={handleChange}
              value={form.businessName}
              error={errorMessages?.businessName}
            />
            <Input
              label={first_step?.city_placeholder}
              onChange={handleChange}
              name="city"
              value={form.city}
              error={errorMessages?.city}
            />
            {states ? (
              <Select
                aria-label={first_step?.state_province_placeholder}
                placeHolder={first_step?.state_province_placeholder}
                name="state"
                value={form.state}
                onChange={handleChange}
                options={states}
                labelField="Name"
                valueField="Code"
                error={errorMessages?.state}
              />
            ) : (
              <Input
                label={first_step?.state_province_placeholder}
                onChange={handleChange}
                name="state"
                value={form.state}
                error={errorMessages?.state}
              />
            )}
          </Fields>

          <FormAction>
            <Button
              onClick={handleNext}
              type={ButtonType.PRIMARY_LIGHT}
              label={first_step?.button_label}
            />
          </FormAction>
        </>
      );
    }

    return (
      <>
        <Fields>
          <Input
            label={second_step?.email_placeholder}
            name="email"
            onChange={handleChange}
            value={form.email}
            error={errorMessages?.email}
          />
          <Input
            label={second_step?.phone_placeholder}
            name="phone"
            onChange={handleChange}
            value={form.phone}
            error={errorMessages?.phone}
          />
          <TextArea
            label={second_step?.which_product_placeholder}
            name="products"
            onChange={handleChange}
            value={form.products}
            error={errorMessages?.products}
          />
          <TextArea
            label={second_step?.which_distributors_placeholder}
            name="distributors"
            onChange={handleChange}
            value={form.distributors}
            error={errorMessages?.distributors}
          />

          <div>
            <Checkbox
              name="agreement"
              checked={form.agreement ?? true}
              onChange={handleChange}
              label={second_step?.disclaimer}
            />
          </div>
        </Fields>

        <FormAction>
          <Button
            onClick={back}
            type={ButtonType.SECONDARY_LIGHT}
            label={second_step?.left_button_label}
          />
          <Button
            submit
            onClick={handleSubmit}
            type={ButtonType.PRIMARY_LIGHT}
            label={second_step?.right_button_label}
          />
        </FormAction>
      </>
    );
  };

  // reset state after country changed
  useEffect(() => {
    setForm((prev) => ({ ...prev, state: "" }));
  }, [form.country]);

  return (
    <FormContainer>
      <FormHeader>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderDescription>{description}</HeaderDescription>
      </FormHeader>

      <Steps>
        <Step
          passed={isFirstStepValid()}
          step={1}
          current={step}
          label={first_step?.tab_form_title}
        />
        <Step
          passed={isSecondStepValid()}
          step={2}
          current={step}
          label={second_step?.tab_form_title}
        />
      </Steps>

      {renderContent()}

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_KEY}
      />
    </FormContainer>
  );
}

const Step = ({
  step,
  passed,
  current,
  label,
}: {
  step: number;
  passed: boolean;
  current: number;
  label: string;
}) => {
  return (
    <StyledStep isCurrent={current === step && !passed} passed={passed}>
      <span>{passed ? <InlineSVG src={checkmark} /> : step}</span>
      <div dangerouslySetInnerHTML={{ __html: label }} />
    </StyledStep>
  );
};
const StyledStep = styled.div<{ passed: boolean; isCurrent: boolean }>`
  display: flex;
  align-items: center;
  gap: ${vwMobile(12)};
  letter-spacing: 0.08em;
  font-size: ${rem(18)};
  font-weight: 700;
  text-transform: uppercase;

  color: ${(p) => (!p.isCurrent ? "white" : "#75c154")};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(12)};
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex: 0 0 auto;

    width: ${vwMobile(40)};
    height: ${vwMobile(40)};

    font-size: ${rem(20)};
    line-height: ${rem(20)};
    font-weight: 800;

    border: ${vwMobile(3)} solid;
    border-radius: 50%;

    color: ${(p) => (p.isCurrent ? "currentColor" : "black")};
    border-color: ${(p) =>
      p.isCurrent ? "currentColor" : p.passed ? "#75c154" : "white"};
    background-color: ${(p) =>
      p.isCurrent ? "transparent" : p.passed ? "#75c154" : "white"};

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(40)};
      height: ${vwDesktop(40)};
      border-width: ${vwDesktop(3)};
    }
  }
`;

const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${vwMobile(20)};

  ${MEDIA_DESKTOP} {
    justify-content: flex-end;
    gap: ${vwDesktop(20)};
    margin-top: ${vwDesktop(60)};
  }

  > * {
    flex: 1;
    ${MEDIA_DESKTOP} {
      flex: 0 0 auto;
      width: ${vwDesktop(180)};
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(16)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(16)};
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(40)};
  color: white;

  background-color: #012102;
  padding: ${vwMobile(40)} ${vwMobile(20)} ${vwMobile(36)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(45)};
    width: ${vwDesktop(610)};
    padding: ${vwDesktop(40)};
  }

  h3 {
    font-size: ${rem(36)};
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: ${vwMobile(15)};
    letter-spacing: 0.03em;

    ${MEDIA_DESKTOP} {
      margin-bottom: ${vwDesktop(15)};
    }
  }
`;

const FormHeader = styled.header``;

const HeaderTitle = styled.h3`
  font-size: ${rem(36)};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: ${vwMobile(15)};
  letter-spacing: 0.03em;
  line-height: ${vwMobile(36)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(15)};
    line-height: normal;
  }
`;

const HeaderDescription = styled.p`
  font-size: ${rem(18)};
  line-height: ${vwMobile(28)};
  font-weight: 600;

  ${MEDIA_DESKTOP} {
    line-height: ${vwDesktop(28)};
  }
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(20)};

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${vwDesktop(40)};
    width: 90%;
  }
`;

const SuccessMessage = styled.p`
  width: 100%;
  text-align: center;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 800;
  letter-spacing: 0.08em;
`;
