import { useMediaQuery } from "@react-hook/media-query";
import React, { useEffect, useRef, useState } from "react";
import { animated, useSpringRef, useTransition } from "react-spring";
import styled from "styled-components";
import QuoteImage from "../../assets/images/quote.svg";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA, MEDIA_DESKTOP } from "../../helpers/theme";
import PlayButton, {
  PlayButtonRef,
} from "../shared/PlayArrowButton/PlayArrowButton";

const SLIDE_ANIMATION_DURATION = 12;

type Card = {
  quote: string;
  name: string;
  job_role: string;
};

export type PartnersReviewsProps = {
  title: string;
  cardList: Card[];
};

export default function PartnersReviews(
  props: PartnersReviewsProps
): JSX.Element | null {
  const isMobile = useMediaQuery(MEDIA.MOBILE);
  const slides = props.cardList || [];

  const playButtonRef = useRef<PlayButtonRef>(null);

  const [index, setIndex] = useState(0);
  const transRef = useSpringRef();

  if (slides?.length === 0) return null;

  const transitions = useTransition(index, {
    ref: transRef,
    keys: index,
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0 },
  });

  const handleNext = () => {
    setIndex((prev) => {
      const newIndex = prev === slides?.length - 1 ? 0 : prev + 1;
      return newIndex;
    });
    playButtonRef.current?.reset();
  };

  const handlePrev = () => {
    setIndex((prev) => {
      const newIndex = prev === 0 ? slides?.length - 1 : prev - 1;
      return newIndex;
    });
    playButtonRef.current?.reset();
  };

  useEffect(() => {
    transRef.start();
  }, [index]);

  return (
    <Container>
      <Heading>{props.title}</Heading>

      <Quotes>
        {isMobile && (
          <Horizontal>
            <QuoteMark src={QuoteImage} alt={"quote"} />
            <PlayArrows>
              <PlayButton
                duration={SLIDE_ANIMATION_DURATION}
                onClick={handleNext}
                onAnimationEnd={handleNext}
                iconColor="white"
                bgColor="#012102"
                progressColor="white"
                borderColor="#DD9271"
                animated
              />
              <PlayButton
                onClick={handlePrev}
                iconColor="white"
                bgColor="#012102"
                borderColor="#DD9271"
                rotate
              />
            </PlayArrows>
          </Horizontal>
        )}

        {!isMobile && <QuoteMark src={QuoteImage} alt={"quote"} />}

        {slides?.length > 0 && (
          <QuoteSlider>
            {transitions((style, i) => {
              const card = slides[i];
              return (
                <Quote style={style}>
                  <p>{card.quote}</p>
                  <Author>
                    {card.name}
                    <small>{card.job_role}</small>
                  </Author>
                </Quote>
              );
            })}
          </QuoteSlider>
        )}

        {!isMobile && (
          <PlayArrows>
            <PlayButton
              ref={playButtonRef}
              duration={SLIDE_ANIMATION_DURATION}
              onClick={handleNext}
              onAnimationEnd={handleNext}
              iconColor="white"
              bgColor="#012102"
              progressColor="white"
              borderColor="#dd9271"
              animated
            />
            <PlayButton
              onClick={handlePrev}
              bgColor="#012102"
              borderColor="#dd9271"
              iconColor="white"
              rotate
            />
          </PlayArrows>
        )}
      </Quotes>
    </Container>
  );
}

const Horizontal = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlayArrows = styled.div`
  display: flex;
  gap: ${vwMobile(20)};
  direction: ltr;
  flex-direction: row-reverse;

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
    direction: inherit;
    flex-direction: column;
    margin-left: ${vwDesktop(20)};
    margin-top: ${vwDesktop(29)};
  }
`;

const QuoteSlider = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  > * {
    grid-column: 1;
    grid-row: 1;
  }

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(820)};
  }
`;

const Quote = styled(animated.blockquote)`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(20)};

  color: #012102;
  font-weight: 900;
  font-size: ${rem(36)};
  line-height: ${rem(40)};
  text-transform: uppercase;
  padding-top: ${vwMobile(5)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(65)};
    font-size: ${rem(48)};
    line-height: ${rem(56)};
    padding-top: ${vwDesktop(5)};
  }
`;

const Container = styled.section`
  background-color: #dd9271;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  padding: ${vwMobile(45)} ${vwMobile(20)};
  gap: ${vwMobile(73)};
  display: flex;
  flex-direction: column;

  ${MEDIA_DESKTOP} {
    padding: ${vwDesktop(82)} ${vwDesktop(244.7)} ${vwDesktop(104)}
      ${vwDesktop(160)};
    align-items: center;
    gap: ${vwDesktop(81)};
  }
`;

const Quotes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: ${vwMobile(34)};

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${(props) => (props.theme.isRTL ? `${vwDesktop(30)}` : 0)};
    width: ${vwDesktop(968)};
  }
`;

const QuoteMark = styled.img`
  object-fit: contain;

  width: ${vwMobile(63)};
  height: ${vwMobile(41)};
  ${MEDIA_DESKTOP} {
    margin-top: ${vwDesktop(8)};
    margin-right: ${vwDesktop(45)};
    width: ${vwDesktop(96)};
    height: ${vwDesktop(61)};
  }
`;

const Author = styled.div`
  font-size: ${rem(24)};
  line-height: ${rem(31)};
  font-weight: 900;
  letter-spacing: 0.06em;
  color: #623017;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  gap: ${vwMobile(9)};

  ${MEDIA_DESKTOP} {
    font-size: ${rem(36)};
    line-height: ${rem(40)};
    gap: ${vwDesktop(6)};
  }

  small {
    color: #5f2b11;
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    font-weight: 800;
    letter-spacing: 0.08em;
  }
`;

const Heading = styled.h3`
  color: #012102;
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 800;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`;
