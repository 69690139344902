import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { isMobile } from "react-device-detect";
import { rem, vwDesktop, vwMobile } from "../../../helpers/styles";
import { MEDIA_DESKTOP } from "../../../helpers/theme";
import ErrorMessage, {
  COLOR_ON_LIGHT,
  COLOR_ON_DARK,
} from "../ErrorMessage/ErrorMessage";

export type TextAreaProps = {
  label: string;
  value: string;
  error?: string;
  bgType?: "light" | "dark";
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const bgMap = {
  light: COLOR_ON_LIGHT,
  dark: COLOR_ON_DARK,
};
export const TextArea = ({
  label = "",
  value = "",
  error = "",
  bgType = "dark",
  ...rest
}: TextAreaProps): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const id = useRef<string>(nanoid());
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputElement = inputRef.current;

  function toggleFocus() {
    setFocused((f) => !f);
  }

  const getLabel = useCallback(() => {
    if (isMobile && focused) {
      const focusedLabelMaxLength = 45;
      return label?.length > focusedLabelMaxLength
        ? `${label?.slice(0, focusedLabelMaxLength)}...`
        : label;
    }
    return label;
  }, [focused, label]);

  return (
    <div>
      <Container
        onClick={() => inputElement?.focus()}
        hasErrors={!!error}
        bgType={bgType}
      >
        {label?.length > 0 && (
          <label
            htmlFor={rest?.id || id?.current}
            className={value?.length > 0 || focused ? "up" : ""}
          >
            {getLabel()}
          </label>
        )}
        <textarea
          ref={inputRef}
          value={value}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          {...rest}
          id={rest?.id || id?.current}
        />
      </Container>
      {error && <ErrorMessage message={error} bgType={bgType} />}
    </div>
  );
};

const Container = styled.div<{ hasErrors: boolean; bgType: string }>`
  width: 100%;
  height: ${vwMobile(122)};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;

  padding-top: ${vwMobile(30)};
  border: ${vwMobile(2)} solid
    ${(p) => (p.hasErrors ? bgMap[p.bgType] : "#000000")};

  background-color: #ffffff;

  position: relative;

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(122)};
    padding-top: ${vwDesktop(30)};
    border-width: ${vwDesktop(2)};
  }

  textarea {
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    width: 100%;
    height: 100%;
    padding: 0 ${vwMobile(20)};
    margin: 0;
    border: 0;

    font-size: ${rem(16)};
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-weight: 700;

    z-index: 1;
    outline: none;
    resize: none;

    ${MEDIA_DESKTOP} {
      padding: 0 ${vwDesktop(20)};
    }
  }

  label {
    display: flex;
    width: 100%;
    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    padding: 0 ${vwMobile(16)};
    position: absolute;
    top: ${vwMobile(16)};
    font-size: ${rem(16)};
    color: #000000;
    font-weight: 700;
    z-index: 2;
    transition: all 0.2s;

    &.up {
      font-size: ${rem(12)};
      transform: translateY(-50%);
      transform-origin: left center;
    }

    ${MEDIA_DESKTOP} {
      top: ${vwDesktop(20)};
      padding: 0 ${vwDesktop(16)};
    }
  }
`;

export default TextArea;
